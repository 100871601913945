import React from 'react';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, InputAdornment, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DialCodes, FormElements, NotifyTypo, useResolutionCheck } from 'web-components';
import { CloudflareCaptcha } from 'web-components-ts';
import { CLOUDFLARE_CAPTCHA_SITE_KEY } from 'attrs/recaptcha';

import { AccountRegisterSchema } from '../../../attrs/formValidation';
import { registerUser } from '../../../redux/auth/actions';
import { getRegister } from '../../../redux/auth/selectors';
import { getDialCodeFromCountryCode, isError, isLoading } from '../../../helpers/utils';
import { DATA_PRIVACY_LINKS, LANGUAGES } from '../../../attrs/languages';
import { COLOR_MAIN_HIGHLIGHT, PRIMARY_TEXT_COLOR } from '../../../attrs/colors';
import FormAlert from '../FormAlert';
import { StyledFooterBar, StyledFooterBarContainer } from '../../../elements';

const { Heading2, Body1 } = NotifyTypo;

const RegisterAccountForm = ({ token, initLanguage }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isMobile } = useResolutionCheck();

  const handleRegister = values => dispatch(registerUser(values, token, values.recaptchaValue));
  const registerLoadingState = useSelector(getRegister);

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        phone: {
          country: DialCodes[0].code,
          number: ''
        },
        password: '',
        confirm_password: '',
        language: initLanguage,
        terms_accept: false,
        recaptchaValue: ''
      }}
      validationSchema={AccountRegisterSchema}
      onSubmit={values => handleRegister(values)}
    >
      {({ values, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, errors, touched }) => {
        const selectedDialCode = getDialCodeFromCountryCode(values.phone.country);

        const handleLanguageChange = e => {
          i18n.changeLanguage(e.target.value);
          setFieldValue('language', e.target.value);
        };

        return (
          <form>
            <Heading2 gutterBottom>{t('profile.language_setting')}</Heading2>
            <Body1>{t('profile.language_setting_subtext')}</Body1>
            <FormElements.Select
              value={values.language}
              handleChange={handleLanguageChange}
              fullWidth
              label={t('profile.languages.language')}
              name="language"
              id="language"
            >
              {LANGUAGES.map(language => (
                <option key={language.value} value={language.value}>
                  {t(language.label)}
                </option>
              ))}
            </FormElements.Select>
            <Heading2 gutterBottom style={{ marginTop: '2rem' }}>
              {t('profile.personal_information')}
            </Heading2>
            <FormElements.TextField
              label={t('profile.first_name')}
              fullWidth
              id="firstName"
              margin="normal"
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
              required
              error={errors.first_name && touched.first_name}
              helperText={errors.first_name && touched.first_name && t(`form.validate.${errors.first_name}`)}
            />
            <FormElements.TextField
              label={t('profile.last_name')}
              fullWidth
              id="lastName"
              margin="normal"
              name="last_name"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              error={errors.last_name && touched.last_name}
              helperText={errors.last_name && touched.last_name && t(`form.validate.${errors.last_name}`)}
            />
            <FormElements.Select
              value={values.phone.country}
              fullWidth
              handleChange={handleChange}
              label={t('profile.country_code')}
              name="phone.country"
              id="phoneCountry"
            >
              {DialCodes.map(country => (
                <option key={country.code} value={country.code}>
                  {`${t(country.name)} (${country.dial_code})`}
                </option>
              ))}
            </FormElements.Select>
            <FormElements.TextField
              label={t('profile.phone')}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{selectedDialCode}</InputAdornment>
              }}
              id="phoneNumber"
              margin="normal"
              name="phone.number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone.number}
              required
              error={((errors || {}).phone || {}).number && ((touched || {}).phone || {}).number}
              helperText={
                ((errors || {}).phone || {}).number &&
                ((touched || {}).phone || {}).number &&
                t(`form.validate.${errors.phone.number}`)
              }
            />
            <Heading2 gutterBottom style={{ marginTop: '2rem' }}>
              {t('profile.account_settings')}
            </Heading2>
            <FormElements.TextField
              label={t('profile.password')}
              fullWidth
              id="password"
              margin="normal"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              required
              type="password"
              error={errors.password && touched.password}
              helperText={
                errors.password &&
                touched.password &&
                errors.password &&
                touched.password &&
                t(`form.validate.${errors.password}`)
              }
            />
            <FormElements.TextField
              label={t('profile.confirm_password')}
              fullWidth
              id="confirm_password"
              margin="normal"
              name="confirm_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              required
              type="password"
              error={errors.confirm_password && touched.confirm_password}
              helperText={
                errors.confirm_password &&
                touched.confirm_password &&
                errors.confirm_password &&
                touched.confirm_password &&
                t(`form.validate.${errors.confirm_password}`)
              }
            />
            <div style={{ marginTop: '2rem' }} />
            <FormElements.Checkbox
              activeColor={COLOR_MAIN_HIGHLIGHT}
              name="terms_accept"
              checked={values.terms_accept}
              label={
                <>
                  {t('profile.i_have_read_and_accept')}{' '}
                  <a
                    href={DATA_PRIVACY_LINKS[values.language]}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: PRIMARY_TEXT_COLOR }}
                  >
                    {t('profile.privacy_policy')}
                  </a>
                </>
              }
              onChange={handleChange}
              required
            />

            <CloudflareCaptcha
              siteKey={CLOUDFLARE_CAPTCHA_SITE_KEY}
              onVerify={value => handleChange({ target: { name: 'recaptchaValue', value } })}
            />

            <div style={{ marginTop: '2rem' }} />
            {isError(registerLoadingState.status) && <FormAlert message={t('errors.form_submit')} />}
            <StyledFooterBar position={isMobile ? 'sticky' : 'fixed'}>
              <Toolbar>
                <StyledFooterBarContainer maxWidth="md" theme={theme}>
                  <div />
                  <Button
                    disabled={isSubmitting || Object.keys(errors).length !== 0 || !dirty || !values.recaptchaValue}
                    endIcon={
                      isLoading(registerLoadingState.status) && (
                        <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
                      )
                    }
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    type="submit"
                  >
                    {t('form.apply')}
                  </Button>
                </StyledFooterBarContainer>
              </Toolbar>
            </StyledFooterBar>
          </form>
        );
      }}
    </Formik>
  );
};

RegisterAccountForm.propTypes = {
  token: T.string.isRequired,
  initLanguage: T.string.isRequired
};

export default RegisterAccountForm;
