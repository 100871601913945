import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormElements } from 'web-components';
import { CloudflareCaptcha } from 'web-components-ts';
import { CLOUDFLARE_CAPTCHA_SITE_KEY } from 'attrs/recaptcha';

import { LoginSchema } from '../../../attrs/formValidation';
import { isError, isLoading } from '../../../helpers/utils';
import { login } from '../../../redux/auth/actions';
import { getLogin } from '../../../redux/auth/selectors';
import { StyledLink } from '../../../containers/Auth/SignIn/elements';

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status } = useSelector(getLogin);

  const handleSignIn = values => {
    dispatch(login(values.login, values.password, values.recaptchaValue));
  };

  return (
    <Formik
      initialValues={{
        login: '',
        password: '',
        recaptchaValue: ''
      }}
      validationSchema={LoginSchema}
      onSubmit={values => handleSignIn(values)}
    >
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
        <form>
          <FormElements.TextField
            label={t('auth.email')}
            fullWidth
            id="login"
            margin="normal"
            name="login"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.login}
            required
            error={(errors.login && touched.login) || isError(status)}
            helperText={errors.login && touched.login && t(`form.validate.${errors.login}`)}
          />
          <FormElements.TextField
            autoComplete="current-password"
            error={(errors.password && touched.password) || isError(status)}
            fullWidth
            label={t('auth.password')}
            id="password"
            margin="normal"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            required
            type="password"
            helperText={errors.password && touched.password && t(`form.validate.${errors.password}`)}
          />

          <CloudflareCaptcha
            siteKey={CLOUDFLARE_CAPTCHA_SITE_KEY}
            onVerify={value => handleChange({ target: { name: 'recaptchaValue', value } })}
          />

          <Button
            color="primary"
            endIcon={isLoading(status) && <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />}
            fullWidth
            onClick={handleSubmit}
            disabled={!values.recaptchaValue}
            variant="contained"
            style={{ marginTop: '2rem', marginBottom: '1.5rem', height: '56px' }}
            type="submit"
          >
            {t('auth.login')}
          </Button>
          <StyledLink href="/forgot-password">{t('auth.forgot_password')}</StyledLink>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
